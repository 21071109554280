export const CommonMessages = {
  add: 'Toevoegen',
  back: 'Terug',
  cancel: 'Annuleren',
  check: 'Check',
  code: 'Code',
  comingSoon: 'Nog even geduld! We werken hard aan deze functie.',
  confirm: 'Bevestigen',
  dashboard: 'Dashboard',
  delete: 'Verwijder',
  details: 'Details',
  disable: 'Uitschakelen',
  empty: 'Geen {items} gevonden',
  enable: 'Inschakelen',
  from: 'Vanaf',
  goToSettings: 'Ga naar je instellingen',
  oneTime: 'Eenmalig',
  quantity: 'Aantal',
  month: 'Maand',
  months: '0 maanden | 1 maand | {n} maanden',
  next: 'Volgende',
  no: 'Nee',
  per: 'Per',
  save: 'Opslaan',
  search: 'Zoeken',
  status: 'Status',
  submit: 'Opslaan',
  total: 'Totaal',
  type: 'Type',
  until: 't/m',
  welcome: 'Welkom {name}',
  yes: 'Ja',
};
