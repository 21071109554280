import { RecurringBillingFrequency } from '../../services/hubspot/recurring-billing-frequency.enum.ts';

const recurringBillingFrequencies: { [key in RecurringBillingFrequency]: string } = {
  annually: 'jaar',
  monthly: 'maand',
  one_time: 'eenmalig',
  quarterly: 'kwartaal',
};

export const ProductMessages = {
  title: 'Product',

  nonRecurringCosts: 'Eenmalige kosten',
  ppu: 'Prijs per eenheid',
  recurringCosts: 'Terugkerende kosten',

  properties: {
    recurringBillingFrequency: 'Factureringsfrequentie',
  },

  recurringBillingFrequencies,
};
